
/**
 *
 *
 * @export
 * @param boolean visible
 * @param array msgs {type:"info",title:"TITLE",content:"content",delay:3000}
 * @return {*} 
 */
export default function Loading({visible,msgs=""}){
    return(
        <>{visible?
            <div id="loading_wrapper" className="loading__wrapper" style={{display:visible?'block':'none'}}>
                <div className="loading"></div>
                <div className="row">
                    <div className="col-auto">
                        <div className="loading__spinner">
                            <div className="spinner-border"></div>
                        </div>
                    </div>
                    <div className="col-auto pt-2">{msgs}</div>
                </div>
            </div>:""}
        </>
    );
}
