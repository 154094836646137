import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Form from './view/Form';
import Result from './view/Result';

//add script
let adds={
	head:[
		{
			tag:'link',
			attr:{
				rel:"stylesheet",
				href:"https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css",
				integrity:"sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3",
				crossorigin:"anonymous"
			}
		}
	],
	body:[
		{
			tag:'script',
			attr:{
				src:"https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta1/dist/js/bootstrap.bundle.min.js",
				integrity:"sha384-ygbV9kiqUc6oa4msXn9868pTtWMgiQaeYH7/t7LECLbyPA2x65Kgf80OJFdroafW",
				crossorigin:"anonymous"
			}
		},
		{
			tag:'script',
			attr:{
				src:"https://cdn.jsdelivr.net/npm/@popperjs/core@2.5.4/dist/umd/popper.min.js",
				integrity:"sha384-q2kxQ16AaE6UbzuKqyBE9/u/KzioAlnx2maXQHiDX9d4/zp8Ok3f+M7DPm+Ib6IU",
				crossorigin:"anonymous"
			}
		},
		{
			tag:'script',
			attr:{
				src:"https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta1/dist/js/bootstrap.min.js",
				integrity:"sha384-pQQkAEnwaBkjpqZ8RU1fF1AKtTcHJwFl3pblpTlHXybJjHpMYo79HY3hIi4NKxyj",
				crossorigin:"anonymous"
			}
		}
	]
};
for(let i=0;i<adds['head'].length;++i){
	let tag=adds['head'][i]
	let add=document.createElement(tag['tag']);
	Object.keys(tag['attr']).forEach(key=>{
		add.setAttribute(key,tag['attr'][key]);
	})
	document.head.appendChild(add);
}
for(let i=0;i<adds['body'].length;++i){
	let tag=adds['body'][i]
	let add=document.createElement(tag['tag']);
	Object.keys(tag['attr']).forEach(key=>{
		add.setAttribute(key,tag['attr'][key]);
	})
	document.body.appendChild(add);
}
const form=document.getElementsByClassName('canteach-serma-project__form-wrapper');
for(let i=0;i<form.length;++i){
	if(form[i].dataset.id){
		ReactDOM.render(
			<Form id={form[i].dataset.id} index={i} key={i} />,
			form[i]
		);
		form[i].removeAttribute('data-id')
	}
}

const applied=document.getElementsByClassName('canteach-serma-project__apply-result');
for(let i=0;i<applied.length;++i){
	if(!applied[i].dataset.result){
		ReactDOM.render(
			<Result key={i} index={i} />,
			applied[i]
		);
		applied[i].dataset.result=true;
	}
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
