import { createKey } from "./keys";

let url;
if(window.location.hostname=='localhost'){
    url='http://localhost/canteach/login/api/';
}else{
    url='https://login.canteach.jp/api/';
}
export const ApiUrl=url;

/**
 * 
 * @param {*} send 
 * endpoint:
 * method
 * body
 * @returns 
 */
export default function Fetch(send){
    let headers={
        "Accept":"application/json",
        "Content-Type":"application/json",
        "Canteach-Nonce":createKey(),
        "Canteach-Version":1,
        "Canteach-Endpoint":send['url'],
        "Canteach-Method":send['method'],
        "Canteach-Token":"none",
        "Canteach-Short":"",
        "Canteach-Value":""
    };
    return getFetch(headers,send['body']?send['body']:{});
}
function getFetch(headers,body){
    return new Promise((resoleve,reject)=>{
        fetch(url,{
            method:'POST',
            headers:headers,
            body:JSON.stringify(body)
        }).then(res=>res.json())
        .then(data=>{
            if(data['ResultInfo']['nonce']==headers['Canteach-Nonce']){
                return resoleve(data);
            }else{
                data['error']={
                    title:"Nonceエラー",
                    description:[["Nonceが一致しません。"]]
                };
                return reject(data);
            }
        }).catch((error)=>{
            return reject(error);
        });
    })
}