import { useEffect, useRef, useState } from "react";
import AlertBox from "./../controller/AlertBox";
import Loading from "./../controller/Loading";
import Fetch from "./../controller/Fetch";
import { Table } from "react-bootstrap";
import { ConvertToDisplayDate, number_format } from "./../controller/keys";
import "./serma.css";
import {loadStripe} from "@stripe/stripe-js";
import {CardCvcElement, CardExpiryElement, CardNumberElement, Elements, useElements, useStripe} from "@stripe/react-stripe-js";
import SendJson from "./../controller/SendJson";

let StripeToken="";
export default function Form({id,index}){
	const [Alert,setAlert]=useState();
	const [Visible,setVisible]=useState(false);
	const [Item,setItem]=useState();
	const [Btn,setBtn]=useState({});
	const [Seminar,setSeminar]=useState();
	const Wrapper=useRef();
	useEffect(()=>{
		let send={
			url:`v1/serma/project/${id}/student/apply`,
			method:'GET'
		}
		Fetch(send).then(data=>{
			if(data['result']){
				setItem(data['payloads']);
				setBtn(data['payloads']['projects']['btns']['RegularStyle']);
			}else{
				setAlert({type:'danger',msgs:data['error']['description']})
			}
		}).catch(error=>console.log(error));
	},[]);
	function MouseOver(){
		setBtn(Item['projects']['btns']['HoverStyle']);
	}
	function MouseLeave(){
		setBtn(Item['projects']['btns']['RegularStyle']);
	}
	function selectSeminar(e){
		setSeminar(Item['seminars'][e.target.dataset.id]);
	}
	function doEntry(){
		setVisible(true);
		setBtn(Item['projects']['btns']['RegularStyle']);
        setAlert();
		const {error,body}=SendJson(Wrapper.current.getElementsByClassName('js-data'),true);
		if(!Seminar){
			error.push('セミナーを選択してください');
		}else{
			if(Seminar['price']>0 && StripeToken==""){
				error.push('決済情報を入力してください');
			}
		}
		if(error.length){
			setAlert({type:'danger',msgs:error});
			setVisible(false);
			window.scroll({top:0});
			return;
		}else{
			body['SeminarId']=Seminar['SeminarId'];
			body['payments']={
				method:'stripe',
				StripeToken:StripeToken
			};
			let send={
				url:`v1/serma/project/${id}/student/apply`,
				method:'POST',
				body:body
			}
			Fetch(send).then(data=>{
				if(data['result']){
					window.location.href=Item['projects']['thanks']+"?ApplyId="+encodeURI(data['payloads']['ApplyId']);
				}else{
					setAlert({type:'danger',msgs:data['error']['description']});
				}
				setVisible(false)
			}).catch(e=>{
				console.log(e);
				setAlert({type:'danger',msgs:["通信エラーが発生しました"]});
				setVisible(false)
			})
		}
	}
	return (
		<>
			{Alert?<AlertBox type={Alert['type']} visible={true} msgs={Alert['msgs']} />:""}
			{Item?
			<div className="w-100" ref={Wrapper}>
				<h2>
					日程一覧
					<img src="https://canteach.jp/assets/img/calendar.jpg" className="canteach-serma-project__title-icon" alt="カレンダー" />
				</h2>
				<p>ご希望の勉強会日程を下記から選び，ご予約ください。</p>
				<Table striped hover className="canteach-serma-project__table">
					<thead className="canteach-serma-project__table-head">
						<th style={{textAlign:"center"}}></th>
						<th style={{textAlign:"center"}}>開催日</th>
						<th style={{textAlign:"center"}}>開催時間</th>
						<th style={{textAlign:"center"}}>開催場所</th>
						<th style={{textAlign:"center"}}>講師</th>
						<th style={{textAlign:"center"}}>座席数</th>{Item['projects']['account']?
						<th style={{textAlign:"center"}}>金額</th>:""}
					</thead>
					<tbody>{Item['seminars'].map((seminar,i)=>
					<tr key={seminar['SeminarId']}>
						<td>{seminar['people']>0?
							<input type="radio" name="SeminarId" value={seminar['SeminarId']} id={`${index}-${seminar['SeminarId']}`} data-id={i} onChange={selectSeminar} />:"×"}
						</td>
						<td>
							<label htmlFor={`${index}-${seminar['SeminarId']}`}>
								{ConvertToDisplayDate(seminar['holded'][0])}
							</label>
						</td>
						<td>
							<label htmlFor={`${index}-${seminar['SeminarId']}`}>
								{seminar['holded'][1]}-{seminar['holded'][2]}
							</label>
						</td>
						<td>
							<label htmlFor={`${index}-${seminar['SeminarId']}`}>
								{seminar['location']}
							</label>
						</td>
						<td>
							<label htmlFor={`${index}-${seminar['SeminarId']}`}>
								{Item['projects']['TeacherName']}
							</label>
						</td>
						<td>
							<label htmlFor={`${index}-${seminar['SeminarId']}`} className="canteach-serma-project__label-number" style={{width:"auto"}} data-id={seminar['people']}>
								{seminar['people']}
							</label>
						</td>{Item['projects']['account']?
						<td>
							<label htmlFor={`${index}-${seminar['SeminarId']}`}>
								{number_format(seminar['price'])}円
							</label>
						</td>:""}
					</tr>
					)}</tbody>
				</Table>{Item['projects']['name']?
				<div className="my-2">
					<div className="input-group">
						<span className="input-group-text">
							お名前
						</span>
						<input type="text" name="name" className="form-control js-data" placeholder="お名前を入力" required />
					</div>
				</div>:""}
				<div className="my-2">
					<div className="input-group">
						<span className="input-group-text">
							メールアドレス
						</span>
						<input type="email" name="email" className="form-control js-data" placeholder="メールアドレスを入力" required />
					</div>
				</div>{(Seminar && Item['projects']['account'])?
				<div className="my-2">
					<p>お支払金額：{number_format(Seminar['price'])}円</p>
					{Item['projects']['payments']['stripe']?<Payment stripeKey={Item['projects']['payments']['stripe']} />:""}
				</div>:""}
				<div className="mt-2">
					<div className="row">
						<div className="col-auto">
							<button type="button" style={Btn} onMouseOver={MouseOver} onMouseLeave={MouseLeave} onClick={doEntry} disabled={Visible}>
								{Item['projects']['btns']['text']}
							</button>
						</div>
						<div className="col-auto pt-3">
							<Loading visible={Visible} msgs="通信中" />
						</div>
					</div>
				</div>
			</div>:<Loading visible={true} msgs={"データ取得中"} />}
		</>
	);
}
function Payment({stripeKey}){
	const stripePromise=loadStripe(stripeKey);
	return (
		<div>
			<Elements stripe={stripePromise}>
				<StripePayment />
			</Elements>
		</div>
	);
}
function StripePayment(){
    const stripe=useStripe();
    const elements=useElements();

	const Token=useRef();

    const [Alert,setAlert]=useState(false);
    const [Card,setCard]=useState({cardNumber:false,cardExpiry:false,cardCvc:false});//cardNumber,expiry,cvc
    const [Errors,setErrors]=useState({cardNumber:'',cardExpiry:'',cardCvc:''});
	
    function displayCardError(e){
        changeError(e);
    }
    function displayExpiryError(e){
        changeError(e);
    }
    function displayCvcError(e){
        changeError(e);
    }
    function checkCard(card){
        setCard(card);
        if(!card['cardNumber']){
            elements.getElement('cardNumber').focus();
        }else if(!card['cardExpiry']){
            elements.getElement('cardExpiry').focus();
        }else if(!card['cardCvc']){
            elements.getElement('cardCvc').focus();
        }else if(card['cardNumber'] && card['cardExpiry'] && card['cardCvc']){
            doEntry();
        }
    }
    function changeError(e){
        let error={};
        let card={};
        error[e.elementType]=e.error?e.error.message:false;
        card[e.elementType]=!e.error;
        if(e.complete){
            checkCard(Object.assign(Card,card));
            setErrors(Object.assign(Errors,error));
        }else{
            checkCard(Object.assign(Card,card));
            error=Object.assign(Errors,error);
            setErrors(error);
        }
        if(error['cardNumber'] || error['cardExpiry'] || error['CardCvc']){
        	setAlert({'type':'danger','style':true,'msgs':[Errors['cardNumber'],Errors['cardExpiry'],Errors['cardCvc']]});
		}else{
			setAlert({type:'danger',msgs:["確認中"]});
		}
    }
    function doEntry(){
        if(!stripe || !elements){
            setAlert({'type':'danger','style':true,'msgs':['エラーが発生しました。']});
            Token.current.value="";
			StripeToken="";
        }else{
            stripe.createPaymentMethod({
                type:'card',
                card:elements.getElement('cardNumber')
            }).then(result=>{
                if(result.error){
                    setAlert({'type':'danger','style':true,'msgs':[result.error.message]});
                }else{
                    setAlert({'type':'success','style':true,'msgs':["カードを利用できます。"]});
                    Token.current.value=result.paymentMethod.id;
                    StripeToken=result.paymentMethod.id;
                }
            }).catch(error=>{
                setAlert({'type':'danger','style':true,'msgs':['エラーが発生しました。']});
                console.log(error)
                StripeToken="";
            });
        }
    }
	return(
		<>
			<input type="hidden" ref={Token} />
			<div className="input-group mb-2">
                <span className="input-group-text">カード番号</span>
                <CardNumberElement id="card" className="form-control" onChange={displayCardError} options={{showIcon:true,iconStyle:'default'}} />
            </div>
            <div className="input-group my-2">
                <span className="input-group-text">有効期限</span>
                <CardExpiryElement className="form-control" onChange={displayExpiryError} />
            </div>
            <div className="input-group my-2">
                <span className="input-group-text">セキュリティーコード</span>
                <CardCvcElement className="form-control" onChange={displayCvcError} />
            </div>
			{Alert ? <AlertBox type={Alert['type']} msgs={Alert['msgs']} visible={true} />:""}
		</>
	);
}