import { useEffect, useState } from "react";
import Fetch from "../controller/Fetch";

const params=window.location.href.replace(/.{1,}\?/,'').split('&');
let param={};
for(let i=0;i<params.length;++i){
    let splited=params[i].split('=');
    if(splited[1]){
        param[splited[0]]=splited[1];
    }else{
        param[splited[0]]="";
    }
}
export default function Result(){
    const [Item,setItem]=useState();
    useEffect(()=>{
        if(param['ApplyId']){
            let send={
                url:"v1/serma/project/student/apply/"+param['ApplyId'],
                method:'GET'
            }
            Fetch(send).then(data=>{
                if(data['result']){
                    setItem(data['payloads']);
                }
            })
        }
    },[]);
    return (
        <>{Item?
            <div>
                <h3>
                    セミナーの申し込み完了
                </h3>
                <div>{Item['Applyer']['name']?
                    <p>
                        申込者名：{Item['Applyer']['name']}
                    </p>:""}
                    <p>
                        メールアドレス：{Item['Applyer']['email']}
                    </p>
                </div>{Item['payments']?
                <div>
                    <p>
                        決済方法：{Item['payments']['method']}
                    </p>{Item['payments']['receipt']?
                    <p>
                        <a href={Item['payments']['receipt']} target="_blank">領収書</a>
                    </p>:""}
                </div>:""}
            </div>:""}
        </>
    );
}