export default function SendJson(data,RequireCheck=false){
    let res={};
    let error=[];
    for(let i=0;i<data.length;++i){
        if(data[i].required && data[i].value==""){
            error.push((data[i].dataset.label?data[i].dataset.label:data[i].placeholder?data[i].placeholder:data[i].name)+'を入力してください。');
        }
        if(data[i].required && (data[i].type=='checkbox' || data[i].type=="radio") && !data[i].checked){
            error.push((data[i].dataset.label?data[i].dataset.label:data[i].placeholder?data[i].placeholder:data[i].name)+'をチェックしてください。');
        }
        if(data[i].maxLength && data[i].maxLength>0 && data[i].value.length>data[i].maxLength){
            error.push((data[i].dataset.label?data[i].dataset.label:data[i].placeholder?data[i].placeholder:data[i].name)+'の入力文字数が超過しています。'+data[i].maxLength+'文字以内で入力してください。');
        }
        if(data[i].min && Number(data[i].value)<data[i].min){
            error.push((data[i].dataset.label?data[i].dataset.label:data[i].placeholder?data[i].placeholder:data[i].name)+'は'+data[i].min+'以上で入力してください。');
        }
        if(data[i].max && Number(data[i].value)>data[i].max){
            error.push((data[i].dataset.label?data[i].dataset.label:data[i].placeholder?data[i].placeholder:data[i].name)+'は'+data[i].max+'以下で入力してください。');
        }
        if(data[i].type=="number" && (!data[i].dataset.type || data[i].dataset.type=="")){
            data[i].dataset.type="numeric";
        }
        if(data[i].type!="checkbox" && data[i].type!="radio" || ((data[i].type=="checkbox" || data[i].type=='radio') && data[i].checked)){
            let val=data[i].value;
            if(data[i].dataset.type){
                switch(data[i].dataset.type){
                    case 'boolean':
                        if(val=='false' || val==0){
                            val=false;
                        }else{
                            val=Boolean(val);
                        }
                        break;
                    case 'numeric':
                        val=Number(val);
                        break;
                    default:
                }
            }
            res[data[i].name]=val;//val=""?"":val;
        }
    }
    if(RequireCheck){
        return {
            'error':error,
            'body':res
        }
    }else{
        return res;
    }
}