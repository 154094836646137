import reactStringReplace from "react-string-replace";
/**
 *
 *
 * @export
 * @param {*} content
 * @return {*} 
 */
export default function Escape(content){
    if(isFinite(content) && content!=""){
        return Number(content).toLocaleString(undefined, { maximumFractionDigits: 20 });
    }else{
        return (
            <div style={{whiteSpace: 'pre-line'}}>{
                reactStringReplace(content, /(https?:\/\/\S+)/g, (match, i) => (
                    <a key={match + i} href={match} target="_blank">{match}</a>
                ))
            }</div>
        );
    }
}